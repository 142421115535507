'use client'

import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  ReactNode,
} from 'react'
import { CohesionSnippetConfig } from '@redventures/cohesion-utils-react/dist/CohesionSnippet'
import { CohesionProvider } from '@redventures/cohesion-utils-react'

interface CohesionConfigState {
  cohesionConfig: CohesionSnippetConfig
  setCohesionConfig: Dispatch<SetStateAction<CohesionSnippetConfig>>
}

const CohesionConfigContext = createContext<CohesionConfigState | undefined>(
  undefined,
)

interface CohesionConfigProviderProps {
  cohesionConfigProp: CohesionSnippetConfig
  children: ReactNode
}

const CohesionConfigProvider: React.FC<CohesionConfigProviderProps> = ({
  cohesionConfigProp,
  children,
}) => {
  const [cohesionConfig, setCohesionConfig] = useState<CohesionSnippetConfig>(
    () => {
      return cohesionConfigProp
    },
  )

  return (
    <CohesionProvider>
      <CohesionConfigContext.Provider
        value={{ cohesionConfig, setCohesionConfig }}
      >
        {children}
      </CohesionConfigContext.Provider>
    </CohesionProvider>
  )
}

const useCohesionConfig = () => {
  const context = useContext(CohesionConfigContext)

  if (context === undefined) {
    throw new Error(
      'useCohesionConfig must be used within a CohesionConfigProvider',
    )
  }

  return context
}

export { CohesionConfigProvider, useCohesionConfig }
