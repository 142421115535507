'use client'
import { useEffect } from 'react'
import LogRocket from 'logrocket'

const LogRocketSnippet = ({ logRocketAppId }: { logRocketAppId?: string }) => {
  useEffect(() => {
    if (window.cohesion) {
      window.cohesion('ready', () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (window._Cohesion?.sessionId) {
          LogRocket.identify(window._Cohesion.sessionId, { refactor: true })
        }
      })
    }
  }, [logRocketAppId])

  if (!logRocketAppId) {
    return
  }

  LogRocket.init(logRocketAppId, {
    mergeIframes: true,
    childDomains: [
      'https://offers.bankrate.com',
      'https://offers.qa.bankrate.com',
    ],
  })

  return null
}

export default LogRocketSnippet
