import(/* webpackMode: "eager", webpackExports: ["CohesionContext","CohesionProvider","useMonarch","useMonarchRule","useMonarchRequest","useMonarchSlot","useMonarchSlotContext","useMonarchPreampPlacement","useMonarchPreampPlacementContext","usePreamp","usePreampPlacement","usePreampPlacementExists","usePreampAsset","usePreampAssetExists","MonarchSlotContext","MonarchSlot","MonarchPreampPlacementContext","MonarchPreampPlacement"] */ "/home/runner/work/shop.txu.com-next/shop.txu.com-next/node_modules/@redventures/cohesion-utils-react/dist/CohesionProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/shop.txu.com-next/shop.txu.com-next/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/shop.txu.com-next/shop.txu.com-next/node_modules/next/font/local/target.css?{\"path\":\"src/helpers/fonts.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"block\",\"src\":[{\"path\":\"../fonts/IconsUnfilled.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/IconsFilled.woff\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-material-symbols-rounded\"}],\"variableName\":\"icons\"}");
;
import(/* webpackMode: "eager", webpackExports: ["CohesionConfigProvider"] */ "/home/runner/work/shop.txu.com-next/shop.txu.com-next/src/app/CohesionContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/shop.txu.com-next/shop.txu.com-next/src/components/LogRocketProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/shop.txu.com-next/shop.txu.com-next/src/styles/globals.scss");
